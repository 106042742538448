@keyframes ldio-24eutxqm1o1 {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}

.ldio-24eutxqm1o1 div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ldio-24eutxqm1o1 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ldio-24eutxqm1o1 div:nth-child(1) {
  border-color: #dc164e;
  animation-delay: 0s;
}

.ldio-24eutxqm1o1 div:nth-child(2) {
  border-color: #293462;
  animation-delay: -0.5s;
}

.loadingio-spinner-ripple-9yaj8a57f0u {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;

}

.ldio-24eutxqm1o1 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-24eutxqm1o1 div {
  box-sizing: content-box;
}