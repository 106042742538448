#mobile-menu {
  scroll-behavior: unset;
  #nav-icon2 {
    width: 35px;
    height: 35px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon2 span {
    display: block;
    position: absolute;
    height: 5px;
    width: 50%;
    background: var(--secondary);
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #nav-icon2 span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }

  #nav-icon2 span:nth-child(odd) {
    left: 0px;
    border-radius: 9px 0 0 9px;
  }

  #nav-icon2 span:nth-child(1),
  #nav-icon2 span:nth-child(2) {
    top: 0px;
  }

  #nav-icon2 span:nth-child(3),
  #nav-icon2 span:nth-child(4) {
    top: 10px;
  }

  #nav-icon2 span:nth-child(5),
  #nav-icon2 span:nth-child(6) {
    top: 20px;
  }

  #nav-icon2.open span:nth-child(1),
  #nav-icon2.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon2.open span:nth-child(2),
  #nav-icon2.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon2.open span:nth-child(1) {
    left: 6px;
    top: 6px;
  }

  #nav-icon2.open span:nth-child(2) {
    left: calc(50% - 1px);
    top: 7px;
  }

  #nav-icon2.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  #nav-icon2.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  #nav-icon2.open span:nth-child(5) {
    left: 6px;
    top: 17px;
  }

  #nav-icon2.open span:nth-child(6) {
    left: calc(50% - 1px);
    top: 18px;
  }

  //   backdrop
  .backdrop {
    background-color: rgba(20, 20, 20, 0.333);
    height: 100vh;
    width: 100%;
    position: fixed !important;
    right: 0;
    top: 80px;
    overflow: scroll;
    .menu-mobile {
      padding: 0 0px;
      border-top: 1px solid #ececec;
      position: absolute;
      right: 0;
      top: 0px;
      background-color: white;
      height: 100vh;
      width: 310px;
      overflow: scroll;
      padding-bottom: 80px;
    }
  }
  .mobile-links {
    text-decoration: none;
    color: black;
    font-size: 16px;
    padding-left: 30px;
    font-weight: 700;
    svg {
      font-size: 22px !important;
      color: var(--secondary);
    }
  }
  .nav-link {
    text-decoration: none;
    color: black;
    padding: 5px 0 !important;
    font-size: 14px !important;
    font-weight: 500;
  }
  .social-links {
    background-color: white;
    border: 1px solid #ececec;
    display: flex;
    position: fixed;
    bottom: 0px !important;
    width: 100%;
    padding: 10px;
    .bottom-link {
      color: var(--primary);
      padding: 0 10px;
    }
  }
}
