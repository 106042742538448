#quotation {
    top: 300px;
    position: sticky;
    z-index: 1;
}
#quotation .btn-quote-box{
    background-color: var(--secondary);
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    transform: rotate(-90deg);
    position: absolute;
    right: -33px;
    text-transform: capitalize;
    color: white;
    z-index: -4!important;
}
#quotation .btn-quote-box:focus{
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1)!important;
}
#quotation .btn-quote-box.active{
    background-color: var(--primary);
    color: white;
    right: -16px;
}
#quotation .quote-card{
    width: 300px;
    right: 44px;
    top: -80px;
    position: absolute;
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
    border: 0;
    border-radius: 10px;
    height: 390px;
    overflow: scroll;
    
}
.activeCard{
    animation: conditionalOpen 0.1s alternate;
  transition: 0.4s linear; 
}
@keyframes conditionalOpen {
    0%   {width:0px;opacity: 0;}
    100% {width:300;}
}
.closeCard{
    animation: conditionalClose 0.5s alternate-reverse;
    transition: 3s linear; 
}
@keyframes conditionalClose {
    100%   {width:300px;}
    0% {width:0;}
}

#quotation .quote-card input{
    border-radius: 8px;
    margin-bottom: 14px;
    font-size: 13px;
    padding: 10px;
}
#quotation .quote-card input:focus{
    border: 1px solid var(--primary);
    box-shadow: none!important;
}
#quotation .quote-card .submit-btn{
    background-color: var(--primary);
    border-radius: 8px;
    width: 100%;
    color: white;

}
#quotation .quote-card h6{
    color: var(--primary);
    font-size: 20px;
}

@media(max-width:480px){
    #quotation .quote-card {
        top: -180px;
    }
}