#mobileFooter {
  margin-bottom: 60px;
  background-color: #f5f5f5;

  .mobileFooter-details {
    padding: 20px;

    img {
      width: auto;
      height: 80px;
    }

    .accordion {
      margin-top: 20px;

      .acc-1 {
        color: #a8a8a8;

        h5 {
          font-weight: 400;
          font-size: 18px !important;
          margin-bottom: 15px;
        }
      }
    }
  }

  #whatsapp {
    position: fixed;
    bottom: 70px;
    left: 10px;
    background-color: white;
    border-radius: 100px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    z-index: 2;

    img {
      width: auto;
      height: 47px;
      object-fit: contain;
    }
  }
}

.foot-laste {
  margin-top: 15px;
  color: black;
}