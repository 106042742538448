@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');


input {
  /* border-radius: 10px !important; */
}

button {
  border-radius: 10px !important;
}

* {
  font-family: 'Open Sans', sans-serif !important;
}



.splide__arrow--prev {
  transition: opacity 0.4s ease;
}

.splide__arrow--prev:disabled {
  opacity: 0;
  pointer-events: none;
}

.splide__arrow--next {
  transition: opacity 0.4s ease;
}

.splide__arrow--next:disabled {
  opacity: 0;
  pointer-events: none;
}

p {
  font-size: 16px !important;
  font-weight: normal !important;
  font-family: sans-serif !important;
}

.shadow-custom {
  border-radius: 0;
  cursor: pointer;
  border: 0;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.16);
}

.shadow-custom:hover {
  box-shadow: 0 30px 70px rgba(0, 0, 0, 0.1);
  z-index: 2;
  transform: scale(1.1);
  transition: all ease-in-out 0.4s;
}

.careerInside .career-btn {
  border: 1px solid var(--primary);
  color: var(--primary);
  background-color: #fff;
  border-radius: 2px;
  margin-bottom: 12px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  box-shadow: 0 !important;
}

.accordion-button:not(.collapsed) {
  background-color: white !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

/* .................................... Web hosting ..................................... */

.webHosting {
  background-image: url("./assests/bg-img.svg");
}

.webHosting .hosting-card {
  text-align: center;
  background-color: var(--primary);
  border: 0;
  color: #fff;
  padding: 70px 30px;
  /* box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24); */
}

.webHosting .hosting-card h3 {
  line-height: 40px;
  font-weight: 500;
  font-size: 27px;
}

.webHosting .hosting-card p {
  padding: 20px;
}

.webHosting .hosting-card button {
  background-color: var(--secondary);
  color: var(--primary);
  margin: 0px 100px;
  width: 40%;
}

.webHosting .hosting-card button:hover {
  transform: scale(1.1);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24);
}

.header_img {
  margin-top: -150px !important;
  transform: translateX(350%);
  width: 300px;
  height: 300px;
}

.plans {
  margin-top: -160px;
  padding-top: 100px;
  background-color: var(--primary);
}

.plans .plans-card {
  background-color: transparent;
  border: 1px solid #989898;
}

.plans .plans-card .badge {
  border-radius: 30px;
  padding: 8px;
  background-color: var(--secondary) !important;
  margin: 16px 10px;
  font-size: 16px;
}

.plans .plans-card p span {
  font-size: 50px;
  font-weight: 700;
}

.plans .plans-card .plans-btn {
  background-color: var(--secondary);
  color: white;
  border-radius: 30px;
  padding: 12px 30px;
  width: 95%;
  font-weight: bold;
  font-size: 17px;
}

.plans .plans-card .plans-btn:hover {
  transform: scale(1.1);
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.4);
}

.plans .plans-card .desc small {
  color: white;
}

.plans .plans-card .desc small svg {
  color: var(--secondary);
}

/* .................................... end ..................................... */


/* .................................... Wordpress ..................................... */

.wordpress h1 {
  font-size: 50px;
}

.wordpress .wordpress-container {
  width: 70%;
}

.wordpress .wordpress-container h4 {
  font-size: 20px;
}

.wordpress .wordpress-container p {
  font-size: 14px;
}

.wordpress .wordpress-container .card {
  border-radius: 10px !important;
  background-color: white;
  border: 0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.24);
}

.wordpress .wordpress-container .card:hover {
  background-color: var(--primary);
  color: white;
  transition: all ease-in-out 0.4s;
  box-shadow: 10px 1px 20px rgba(0, 0, 0, 0.3);
}

.wordpress .bg-image {
  background: url("./assests/dot-pattern.png");
  width: 100%;
}

/* .................................... FAQpage ..................................... */

.faqpage-container {
  margin-left: 5rem;
  margin-right: 5rem;
}


/* .................................... FAQpage-end ..................................... */


/* .................................... FAQ ..................................... */

 .faqs {
  width: 90%;
} 

.faqs .accordion {
  color: var(--primary);
  border-radius: 6px;
  box-shadow: 0px 0px 2px rgba(133, 133, 133, 0.2);
  border: 0 !important;
}

.faqs .accordion-button {
  color: var(--primary);
  border: 0 !important;
}

.faqs .accourdion-button:hover {
  /* border: 1px solid var(--primary); */
  color: white !important;
}

.faqs .accordion-button:focus {
  /* border: var(--secondary) !important; */
  box-shadow: none !important;
}

/* .faqs .accordion-button:hover{
  background-color: var(--primary);
  color: var(--secondary);
} */
.faqs .accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: var(--primary) !important;
  /* border: 1px solid var(--primary) !important; */
}

/* .faqs .accordion-button:not(.collapsed)::after {
  background-image: url("./assests/minus1.png");
  width: 1px;
  } */

.faqs .accordion-button::after {
  background-image: url("./assests/plus.png");
}

.faqs .accordion-body {
  /* background-color: var(--primary) !important; */
  /* color: #fff !important; */
  /* border-top: 1px solid var(--secondary); */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-left: 20px;
}
.faqs .accordion-body::before{
  content: ".";
  font-size: 55px;
  position: absolute;
  margin-top: -48px;
  margin-left: -18px;
}

/* .................................... Qutation ..................................... */


/* .................................... Qutation ..................................... */
.qutation .background-img img {
  object-fit: cover;
  width: 100%;
  background-size: cover;
  position: sticky;


}

.qutation .background-img {
  position: sticky;
  position: -webkit-sticky;
  background: #f83d23;
  width: 100%;
  height: 500px;
  margin-bottom: 452px;
  top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.qutation h4 {
  color: var(--primary);
}

.qutation .btn-qutation {
  border: 1px solid var(--primary);
  padding: 15px 20px;
  background-color: white;
  color: var(--primary);
  font-size: 14px;
}

.qutation .btn-qutation:focus {
  box-shadow: 0px 1px 6px var(--primary) !important;
}

.qutation .form-control {
  border: 1px solid var(--primary);
}

.qutation .form-control:focus {
  box-shadow: 0px 1px 6px var(--primary) !important;
}

.qutation .submit {
  border-radius: 30px;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  padding: 15px 40px !important;
}

.qutation .submit:hover {
  border: 1px solid var(--primary);
  color: var(--primary);
  background-color: white;
  transition: ease-in 0.4s;
  font-weight: 500;
}

.qutation .qutation-form {
  height: 1380px;
  overflow: scroll;
}

.qutation .example {
  overflow-y: scroll;
  /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.qutation .example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.qutation .example {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* ....................................... END .................................. */




/* .splide__pagination li {
  display: none !important;
} */

.splide__arrow {
  /* background: white !important; */
  width: 1.5em;
  height: 1.5em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.splide__arrow svg {
  width: 1.5rem;
  height: 1rem;
}

.sub-btn {
  /* background-color: var(--primary); */
  background-color: var(--secondary);
  color: white;
  text-align: center;
  width: 100%;
  font-size: 0.9rem;
  padding: 12px;
}

.payment {
  filter: grayscale(1.6);
}

.payment:hover {
  filter: brightness(100%);
  transition: 0.7s;
}

/* .....................................Loading Strip............................. */
.custom-btn-1 {
  border-radius: 4px;
  background-color: var(--secondary) !important;
  ;
  border: none;
  color: #FFFFFF;
  text-align: center;
  padding: 20px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px 0px 5px 0px;
}

.custom-btn-1 span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}



.custom-btn-1 span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.custom-btn-1:hover span {
  padding-right: 25px;
}

.custom-btn-1:hover span:after {
  opacity: 1;
  right: 0;
}

/* Body - Margin Reset */
body {
  margin: 0;
}

ul {
  list-style: none;
}

.custom-btn-1 {
  background-color: var(--primary);
  color: white;
  padding: 12px 17px;
}

.custom-btn-1:hover {
  color: white;
}

.custom-btn-1::after {
  content: '\00bb';
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}


.nav-tabs {
  border: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {

  border: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active::before {
  content: ' \00BB';
  position: absolute;
  margin-left: -20px;
  color: red;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover {
  border-bottom: 2px solid var(--primary) !important;
  border: none;

}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: var(--primary);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:focus-visible {
  border: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:focus-within {
  border: none !important;
}

@media (min-width: 480px) {
  .sticky-footer {
    display: none;
    text-decoration: none;
  }

}



/* Progress Bar */
.progress {
  position: fixed;
  top: 0;
  left: 0;
  height: 10px;
  background-color: purple;
  transition: all linear 0.1s;
  min-width: 1%;
}

.content {
  width: 100%;
  height: 100vh;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gradiant:hover {
  background-color: rgba(228, 228, 228, 0.2);
}

/* .....................................Loading Strip End............................. */

/* Colors */
:root {
  --secondary: #D61C4E;
  --primary: #293462;
  --shadow: 5px 5px 15px #d1d9e6, -5px -5px 15px #fff;
}

.homeColor {
  background-color: #f8f9fa;
}

.homeColor:hover {
  background-color: #4f5a82;
  color: white !important;
}

.carousel-control-next-icon {
  background-color: black;
}

.carousel-control-prev-icon {
  background-color: black;
}

.publicity h1 {
  color: #4f5a82;
  font-size: 4rem;
  font: normal 400 3rem / 1.4 "Poppins", "Open Sans", sans-serif;
  font-weight: 700;
}

.inte-partner {
  background-color: #4f5a82;
}

.inte-partner h4 {
  color: #ffffff;

  font: normal / 1.4 "Poppins", "Open Sans", sans-serif;
  font-weight: 500;
}

.box h3 {
  color: #4f5a82;
  font: normal / 1.4 "Poppins", "Open Sans", sans-serif;
  font-weight: 700;
}

.box p {
  font-family: "Lato", sans-serif;
  font-size: large;
}

.team_heading h1 {
  color: var(--primary);
  font: normal / 1.4 "Poppins", "Open Sans", sans-serif;
  font-weight: 700;
}

.team_heading p {
  font-family: "Lato", sans-serif;
  font-size: x-large;
}

/* -------------------------------------cards start------------------------------------------------------ */

.card-content h1 h5 h6 p {
  font-family: "Lato", sans-serif;
  font-size: medium;
}

.card0-bottom {
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 10%), 0 8px 8px -8px rgb(0 0 0 / 16%);
  z-index: 1;
  height: 100%;
}

.card1-bottom {
  border-bottom: 3px solid #b2beff;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 10%), 0 8px 8px -8px rgb(0 0 0 / 16%);
  z-index: 1;
  height: 100%;
}

.card2-bottom {
  border-bottom: 3px solid #ffc093;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 10%), 0 8px 8px -8px rgb(0 0 0 / 16%);
  z-index: 1;
  height: 100%;
}

.card3-bottom {
  border-bottom: 3px solid #f6bcff;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 10%), 0 8px 8px -8px rgb(0 0 0 / 16%);
  z-index: 1;
  height: 100%;
}

.card4-bottom {
  border-bottom: 3px solid #a0f3ff;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 10%), 0 8px 8px -8px rgb(0 0 0 / 16%);
  z-index: 1;
  height: 100%;
}

.card5-bottom {
  border-bottom: 3px solid #ffdaa2;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 10%), 0 8px 8px -8px rgb(0 0 0 / 16%);
  z-index: 1;
  height: 100%;
}

/* -------------------------------------cards end------------------------------------------------------ */

/* -------------------------------------WHY CHOOSE US------------------------------------------------------ */
.chooseUS {
  background-image: url("/src/assests/dot-pattern.png");
  background-color: #ecfefb;
  position: relative;
  width: 100%;
  height: 100%;
}

.chooseUS h2 {
  font-size: 28px;
}

.chooseUS span {
  color: #eea333;
}

.chooseUsImage {
  background-image: url("/src/assests/about-company.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

/* *****************************************CHOOSE US END***************************** */

/* ******************************************CLIENT SECTION***************************** */

.client {
  /* background-color: #ffffff; */
}

.client p {
  color: var(--secondary);
  font-size: 20px;
}

.client h2 {
  font-size: 40px;
  font-family: Poppins, sans-serif;
  color: #050748;
  font-weight: 600;
}

.business span {
  color: var(--secondary);
}

.member_hover:hover {
  background-color: var(--secondary);
}

.contactus {
  background: url("https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832__480.jpg");
  background-repeat: no-repeat;
  width: 100%;
}

.contactus a {
  color: #4f5a82;
}

.contactus a:hover {
  color: #ffffff;
  background: #4f5a82;
}

.address strong {
  color: #555555;
}

.start {
  background-color: #4f5a82 !important;
}

/* new css */

p {
  font-size: 16px;
  word-spacing: 0.1rem;
  font-weight: 300;
}

.custom-btn {
  background-color: #fff;
  border: 1px solid var(--primary);
  border-radius: 8px;
  color: var(--primary);
  font-weight: bold;
  padding: 10px 20px;
}

.custom-btn:hover {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

/* ..................................................client end .............................................. */
/* ..................................................business-domain .............................................. */
.business-domain {
  /* background-color: #fbf6ee; */
}

/*...................................................... business-domain end ............................................... */
/*------------------------------------------------------ header ----------------------------------------------------- */

.headerDrop {
  width: 150px;
  margin: 10px auto;
  padding: 0;
}

.headerDrop>ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.dropdown .show {
  width: auto !important;
  border-radius: 0%;
  border: 0;
  box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.2);
}

.dropdown a {
  text-decoration: none;

}

.dropdown [data-toggle="dropdown"] {
  display: block;
  position: relative;
  border: solid 1px transparent;
  font-family: 'Open Sans', sans-serif;
  padding: 10px 10px;
  margin: 0;
  outline: 0;
  font-size: 1em;
  text-decoration: none;
  white-space: nowrap;
  word-wrap: normal;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.dropdown [data-toggle="dropdown"] {
  border-color: #d3d3d3;
  background: #f8f8f8;
  color: #333;
}

.dropdown [data-toggle="dropdown"]:hover {
  background: #2c89c6;
}

.dropdown .icon-arrow {
  position: absolute;
  display: block;
  font-size: 0.6em;
  color: #333;
  top: 14px;
  right: 10px;
}

.dropdown .icon-arrow.open {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-transition: -webkit-transform 0.6s;
  -moz-transition: -moz-transform 0.6s;
  -o-transition: -o-transform 0.6s;
  transition: transform 0.6s;
}

.dropdown .icon-arrow.close {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.6s;
  -moz-transition: -moz-transform 0.6s;
  -o-transition: -o-transform 0.6s;
  transition: transform 0.6s;
}

.dropdown .icon-arrow:before {
  content: '\25BC';
}

.dropdown .dropdown-menu {
  max-height: 0;
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown .dropdown-menu li {
  padding: 0;
}

.dropdown .dropdown-menu li a {
  display: block;
  color: #6e6e6e;
  background: #eeeeee;
  -webkit-box-shadow: 0 1px 0 white inset, 0 -1px 0 #d4d4d4 inset;
  -moz-box-shadow: 0 1px 0 white inset, 0 -1px 0 #d4d4d4 inset;
  box-shadow: 0 1px 0 white inset, 0 -1px 0 #d4d4d4 inset;
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.3);
  padding: 10px 10px;
}

.dropdown .dropdown-menu li a:hover {
  background: #f6f6f6;
}

.dropdown .show,
.dropdown .hide {
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.dropdown .show {
  margin-top: -8px;
  display: block;
  max-height: 9999px;
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
  animation: showAnimation 0.5s ease-in-out;
  -moz-animation: showAnimation 0.5s ease-in-out;
  -webkit-animation: showAnimation 0.5s ease-in-out;
  -webkit-transition: max-height 1s ease-in-out;
  -moz-transition: max-height 1s ease-in-out;
  -o-transition: max-height 1s ease-in-out;
  border-radius: 10px;
}

.dropdown .show .nav-link:hover {
  background-color: var(--primary);
  color: white;
  transition: 0.4s;
}

.dropdown .hide {
  max-height: 0;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  animation: hideAnimation 0.4s ease-out;
  -moz-animation: hideAnimation 0.4s ease-out;
  -webkit-animation: hideAnimation 0.4s ease-out;
  -webkit-transition: max-height 0.6s ease-out;
  -moz-transition: max-height 0.6s ease-out;
  -o-transition: max-height 0.6s ease-out;
  transition: max-height 0.6s ease-out;
}




.header .navbar-nav .nav-link {
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
}

.header .navbar-nav .nav-link:hover {
  background-color: var(--primary);
  color: #fff;
  border-radius: 8px;
}

.header .dropdown-item {
  text-transform: capitalize;
}

.header .dropdown-item:hover {
  background-color: var(--primary);
  color: white;
}

/*------------------------------------------------------ End header ----------------------------------------------------- */

/*------------------------------------------------------ Home ----------------------------------------------------- */

.main-content {
  background-color: #fff8f2 !important;
}

.hero {
  background-color: var(--primary);
}

.home h1 {
  font-size: 45px;
}

.home p {
  font-family: open, sans-serif;
  font-size: 18px;
}

.home p span {
  font-family: open, sans-serif;
  font-size: 18px;
  color: #fff000;
}

.second p span {
  color: #fff000;
}

.second p {
  font-family: open, sans-serif;
  font-size: 18px;
}

/*------------------------------------------------------ End Home ----------------------------------------------------- */
.main-content h2 {
  color: var(--primary);
  font-family: open, sans-serif;
}

.main-content h3 span {
  color: var(--secondary);
}

.cross-text {
  display: flex;
}

.cross-text strong {
  color: var(--primary);
}

/*------------------------------------------------------ Home Nav-Tabs ----------------------------------------------------- */
.home-nav-tabs ul {
  display: flex;
  justify-content: center;
  border: none;
}

.home-nav-tabs ul li .nav-link {
  border: none;
  color: #000;
}

.home-nav-tabs ul li .nav-link.active {
  color: var(--primary);
  font-weight: bold;
  background: transparent;
}

/*------------------------------------------------------ Home Nav-Tabs ----------------------------------------------------- */

.footer {
  background-color: #f8f9fa;
}

.footer input {
  border-radius: 8px;
}

.footer span {
  border-radius: 8px;
}

.footer .footer-aside {
  background-color: var(--primary);
}

.footer h5 {
  color: var(--primary);
}

.icons a {
  font-size: 28px;

}

.icons:hover a {
  filter: brightness(100%);
}

/* hide buttons */
.splide__autoplay {
  display: none;
}

.custom-card {
  border: none;
  border-radius: 8px 40px 8px 40px;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 10%), 0 8px 8px -8px rgb(0 0 0 / 16%);
}

.custom-card p {
  font-weight: 400;
}

.office-card {
  border: none;
  border-radius: 8px 8px 8px 8px;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 10%), 0 8px 8px -8px rgb(0 0 0 / 16%);
}

.office-card p {
  font-weight: 400;
}

.office p {
  color: var(--primary);
}

.office h2 {
  font-size: 40px;
  color: var(--primary);
}

.footer-web h3 {
  font-size: 18px;
  color: white;
}

.links ul li {
  text-align: left;
  color: var(--secondary);
}

.links ul li a {
  color: rgb(128, 126, 127) !important;
  text-decoration: none;
}

.footer-foot a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.splide__progress__bar {
  display: none;
}

.footer-content p {
  font-family: open, sans-serif;
  font-size: 16px;
  color: white;
}

.footer-content p span {
  font-size: 20px;
  font-family: open, sans-serif;
  color: var(--primary);
}

/* .......................About us ...................... */


.generalHero a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-weight: 400;
  display: inline-block;
  font-size: 16px;
}

.aboutAgency {
  background-image: url("https://separateweb.com/niwax-demo/images/shape/dot_bg.png");
}

.aboutAgency span {
  color: var(--secondary);
}

.aboutAgency p {
  font-size: 17px;
  line-height: 28px;
}

.analytics {
  font-size: 37px !important;
  font-weight: bold;
}

.chooseUsCard {
  /* background-color: #f7fcff; */
}

.chooseUsCard span {
  color: var(--secondary);
}

.chooseUsCard h2 {
  font-size: 40px;
}

.chooseCardShadow {
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 10%), 0 8px 8px -8px rgb(0 0 0 / 16%) !important;
}

.chooseCardShadow:hover {
  transform: scale(1.1);
  transition: all ease 0.6s;
}

/* ..................Contact us.......................... */

.contactUsHero {
  background-image: url("https://separateweb.com/niwax-demo/images/banner/4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.contactUsHero a {
  text-decoration: none;
  color: var(--primary);
  font-weight: 400;
  display: inline-block;
  font-size: 16px;
}

.field-border input {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.field-border select {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.field-border textarea {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.contactQuestion span {
  /* color: var(--secondary); */
  color: white;
}

.contactQuestion h2 {
  font-size: 40px;
}

.contactQuestion p {
  font-size: 17px;
  font-family: open sans, sans-serif;
  color: #6a6a8e;
}

.contactShadow {
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 10%), 0 8px 8px -8px rgb(0 0 0 / 16%) !important;
}

@media (max-width: 576px) {

  .contactQuestion h2 {
    font-size: 24px;
  }

  .sticky-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    color: #4e4f50;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 0;
  }

  .sticky-links {
    color: rgb(55, 55, 55);
    opacity: 1;
    font-family: Lato, sans-serif;
    font-size: 12px !important;
    padding-top: 5px !important;
    transition: font-size 0.2s ease 0.1s, opacity 0.2s ease;
    text-decoration: none;
  }

  .wellcreatorLogo {
    height: 45px !important;
    width: auto;
  }

  .smallHeading {
    font-size: 16px;
  }

  .cross-border1 {
    border-bottom: 1px solid rgb(88, 82, 82);
    font-size: 22px;
    text-align: center;
    border-right: 1px solid rgb(88, 82, 82);
  }

  .cross-border2 {
    border-bottom: 1px solid rgb(88, 82, 82);
    font-size: 22px;
    text-align: center;
  }

  .cross-border3 {
    border-right: 1px solid rgb(88, 82, 82);
    font-size: 22px;
    text-align: center;
  }

  .cross-border4 {
    font-size: 22px;
    text-align: center;
  }

  .offices-address {
    font-size: 16px;
  }

  .contactQuestion p {
    font-size: 12px;

  }

}



/* ===========================SiteMaps========================== */

.site-continer {
  margin: 30px 80px 80px 80px;
}

.siteheading{
  font-weight: bold;
  color: #f0a50f;
}
.site-line1{
  margin-left: 398px;
  background-color: #f0a50f;
  width: 214px;
  height: 3px;
  margin-bottom: 2rem;
}
.pages-links {
  display: flex;
  flex-direction: row;
  gap: 100px;
  line-height: 2.5rem;
  text-align: justify;

}

.links-list a {
  text-decoration: none;
  color: #383737;
  font-size: 1.2rem;
}

.links-list :hover {
  text-decoration: underline black;

}


.last {
  /* margin-bottom: 2rem; */
  text-transform: uppercase;
  font-weight: 700;
  color: rgb(44, 65, 150);

}

.last:hover {
  color: rgb(12, 23, 70);
  cursor: pointer;
}

.links-list {
  padding-left: 0rem;

}

.site-line {
  background-color: var(--secondary);
  width: 64px;
  height: 2px;
  margin-bottom: 2rem;
}

.column {
  margin-left: 2.5rem;
}

/* ===========================End SiteMape========================== */



/* ......................................Portfolio....................................... */
.portfolioHero {
  background-image: url("https://separateweb.com/niwax-demo/images/banner/5.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.portfolioHero a {
  text-decoration: none;
  color: var(--primary);
  font-weight: 400;
  display: inline-block;
  font-size: 16px;
}

.portfolioWork span {
  color: var(--secondary);
  font-family: Poppins, sans-serif;
}

.portfolioWork h2 {
  font-size: 40px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.portfolioCard {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  width: 100%;
}
@media (max-width:800px){
  .portfolioCard {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    width: 90%;
    height: 80%;
  }
 
}

/* ================================ websiteDevelopment ============================ */
.websiteDevelopment .Banner-module--mainHeading--1JWbm {
  font-size: 45px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.websiteDevelopment .Banner-module--subHeading--1z4JK {
  font-size: 24px;
  font-family: Poppins, sans-serif;
}

.webDevCard {
  background-color: #f7fcff;
}

.webDevCard span {
  color: var(--secondary);
}

.webDevCard h2 {
  font-size: 40px;
}

.develop span b {
  color: var(--primary);
}

.chooseCardShadow {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 13px;
  border-radius: 10px;
  border: 0 !important;
}

.main-heading-36 span {
  color: #dc164e
}
.main-heading-36{
  width: 100%;
  font-weight: bolder;
}
/* ============================= Unity Development ====================  */

hero-counter__container,
.hero-counter__item {
  display: flex;
  margin-top: 50px;

}

.hero-counter__container {
  margin-left: -45px;

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* gap:20px; */
}

.hero-counter__item {
  gap: -100px;
  align-items: end;
  margin-left: -23px;
}



.hero-counter__number {
  width: 100px;
  font-size: 4rem;
  font-weight: 800;
  line-height: .89;
  color: white;
  text-align: end;
  margin-right: 16px;
}

.hero-counter__description {
  font-size: 1.25rem;
  /* font-weight:700; */
  /* line-height:1.33; */
  /* text-transform:uppercase; */
  color: white;
}

.unity-hero-container {
  background: linear-gradient(180deg, var(--yellow, rgb(41, 52, 98)), var(--blue, rgb(3, 4, 73)));


}

.hero-heading-span {
  background: linear-gradient(blue, pink);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.unity h3 {

  font-family: Poppins, sans-serif;
  color: #ffffff;
  font-size: clamp(70px, 4vw, 20px);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -0.1rem;
  padding-left: 2rem;
  /* line-height: 3rem; */
}

.unity-hero-text {
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  font-family: inherit !important;
}



.whyUnity span {
  font-size: 19px;
}

.unity a:hover {
  text-decoration: none;
  color: #030598;
}
.wellcreatorServices h2 {
  font-size: 35px;
}

.wellcreatorServices h3 {
  font-size: 20px;
  font-weight: 400;
  color: #2b2b2b;
}

.needDeveloper h3 {
  font-size: 24px;
  font-family: Poppins, sans-serif;
}

.panels-item {
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 10%), 0 8px 8px -8px rgb(0 0 0 / 16%) !important;
}

.with-h6-style {
  color: #000000 !important;
  font-weight: 700 !important;
}

.unity-card:hover {
  background-color: #dddddd;
}

.unity-card {
  height: 20rem;
  padding: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.unity-card-span {
  font-weight: bolder;
  text-align: justify;

}


.clientlink{
  text-decoration: none;
  color: rgb(15, 15, 15);
}
.clientlink:hover{
  color: rgb(15, 15, 15);
}



/* ========================= TimeLine =================================  */
@media only screen and (min-width: 1170px) {
  .vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    width: 10px;
    height: 10px;
    left: 52%;
    margin-left: -28px;
  }

  .vertical-timeline--animate .vertical-timeline-element-icon.bounce-in:hover {
    width: 10px;
    height: 10px;
    left: 52%;
    margin-left: -28px;
    transform: scale(1.5);
  }
}

@media only screen and (max-width: 1024px) {
  .vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    margin-left: 14px;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgb(0 0 0 / 8%),
      0 3px 0 4px rgb(0 0 0 / 5%);
    box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgb(0 0 0 / 8%),
      0 3px 0 4px rgb(0 0 0 / 5%);
  }

  /* ===========================unity development========================== */

  .hero-main-headings {
    font-size: 3rem !important;
    padding-left: 0rem !important;
  }

  .hero-counter__item {
    margin-left: 13px;
  }

  .unity-keyword {
    font-size: 1.5rem;
    gap: 2.2rem !important;
  }




  /* ===========================unity development End========================== */
 


}

/* unity and Sitemap Responsive
 */
@media (max-width:800px) {
  .hidden-element-1 {
    display: none;
  }

  /* sitemap page responsive */
  .pages-links {
    display: flex;
    flex-direction: column;
    gap: 50px;
    line-height: 2rem;
    text-align: justify;

  }
  .site-line1{
    margin-left: 50px;
    background-color: #f0a50f;
    width: 214px;
    height: 3px;
    margin-bottom: 2rem;
  }
  .site-continer {
    margin: 0rem;
  }

  .column {
    margin-left: 1.5rem;
  }

  /* ===========================Mobile development========================== */
  .unity-card-hover {
    padding: 0rem !important;
    line-height: 2.2rem;
  }

  .unity-card-hover h2 {
    font-size: 1.7rem;
    word-break: break-all;

  }

  .unity-card-span {
    font-weight: bolder;
    font-size: 2.2rem;
  }

  /* =======================End Mobile development========================== */

}