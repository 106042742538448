body {
  margin: 0;
}
.header-fixed {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  /* box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1); */
  background-color: white;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  /* line-height: 60px; */
}
.navbar nav li a {
  color:inherit;
  
  /* flex: 1; */
}

.navbar .logo {
  flex: auto;
  margin-left: auto !important;
}

.logo img {
  width: auto;
  height: 60px;
  object-fit: contain;
}

.navbar .logo a {
  display: block;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}
/* .nav-link:focus{
  color:  !important;
} */
.navbar nav {
  /* flex: 8; */
}

.navbar label {
  /* user-select: none; */
  cursor: pointer;
  padding: 28px 20px;
  position: relative;
  z-index: 3;
}

.navbar label i {
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
  font-style: normal;
  font-weight: normal;
}

.navbar label i:before,
.navbar label i:after {
  content: "";
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.navbar label i:before {
  top: 5px;
}

.navbar label i:after {
  top: -5px;
}

.navbar #navbar-toggle {
  display: none;
}

.header #navbar-toggle:checked~.menu {
  visibility: visible;
  opacity: 0.99;
}

.header #navbar-toggle:checked~label {
  background: #fff;
  border-radius: 50%;
  display: none;
}

.header #navbar-toggle:checked~label i {
  background: transparent;
}

.header #navbar-toggle:checked~label i:before {
  transform: rotate(-45deg);
}

.header #navbar-toggle:checked~label i:after {
  transform: rotate(45deg);
}

.header #navbar-toggle:checked~label:not(.steps) i:before,
.header #navbar-toggle:checked~label:not(.steps) i:after {
  top: 0;
}

.top-button {
  background-color: #D61C4E !important;
}

@media (max-width: 992px) {
  .navbar nav {
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 80vh;
    transition: all 0.3s ease-out;
    display: table;
    background: #3831a9;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .navbar nav ul {
    margin: 0;
    padding: 10px 0;
    display: table-cell;
    vertical-align: middle;
  }

  .navbar nav li {
    display: block;
    text-align: center;
    padding-left: 20px;
    text-align: left;
    font-size: 15px;
    min-height: 20px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.5s ease-out;
  }


  .navbar nav:hover a {
    background-color: red !important;
  }

  .navbar nav li:hover a {
    color: #fff;
    transition: all 0.3s ease-out;
  }

  .navbar nav li a {
    color: #fff;
  }


}

@media (min-width: 992px) {
  .navbar nav ul {
    margin: 0;
    padding: 0;
    display: flex;
    /* justify-content: space-around; */
    text-align: center;
    list-style: none;
  }

  

  .navbar nav li a {
    display: block;
    padding: 0 8px;
    font-size: 13px;
    line-height: 30px;
    /* color: #EEA33F; */
    font-weight: 600;
    padding: 1px 15px 5px;
    white-space: nowrap;
    overflow: visible;
    text-decoration: none;

  }

  .navbar nav li.active {
    background: #555;
  }

  .navbar label {
    display: none;
  }
}

@keyframes mymove {
  50% {
    transform: rotate(180deg);
  }
}

@media (max-width:375px) {
  .navbar nav {
    display: none !important;
  }
}

@media(max-width:480px) {
  .header #navbar-toggle:checked~.menu {
    display: none !important;
  }
}

.active-color {
 color: #EEA33F;
}

/* ------------------- */