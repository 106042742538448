.footer-content {
  background-color: rgb(41, 52, 98);
}
.border-top{
  border-top:none !important ;
}
.footer-web {
  .custom-link {
    text-decoration: none;
    padding: 5px 0px;
    font-size: 14px;
    color: #ffffff;


    &:hover {
      // margin-left: 6px;
      text-decoration: underline;
      transition: 0.2s linear;
      color: #ffffff;
    }
  }

  .custom-heading {
    font-size: 1.3rem;
    font-weight: 500;
    // border-bottom: 3px solid var(--primary);
    width: 40px;
  }

  .text-overflow {
    white-space: nowrap;
  }

  .custom-social-link {
    background-color: rgba(185, 185, 185, 0.23);
    padding: 7px 12px;
    padding-bottom: 12px;
    border-radius: 100px;
    font-size: 18px;
    color: #ffffff;

    &:hover {
      background-color: var(--primary);
      color: white;
      transition: 0.3s linear;
    }
  }
}

#whatsapp {
  position: fixed;
  bottom: 73px;
  left: 30px;
  background-color: white;
  border-radius: 100px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  z-index: 3;

  img {
    width: auto;
    height: 46px;
    object-fit: contain;
  }
}

.active-check {
  color: var(--secondary) !important;

  &::before {
    content: "";
    width: 50px;
    height: 4px;
    background-color: var(--secondary);
    position: absolute;
    top: 0;
    margin-left: -11px;
  }
}

.foot-last {
  color: white !important;
}